.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: white;
  padding: 1.5rem;
  margin-top: 1.5rem;
}

.App-body {
  background-color: #282c34 !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-info {
  min-width: 1.1em;
  min-height: 1.1em;
  font-size: 0.3em;
  border: 1px solid white;
  color: white;
  display: inline-block;
  border-radius: 100%;
  margin-top: 0.7em;
  margin-left: 5px;
  margin-bottom: auto;
  cursor: pointer;
}
